// eslint-disable-next-line import/no-duplicates
import { format, formatDistanceToNow, formatDistanceToNowStrict, parseISO } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import { pt } from "date-fns/locale";

import Vue from "vue";
import moment from "moment";

export const removeAccent = (value = "") => {
  if (!value) return value;
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const hexToRGBA = (hex = "#000000", alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
  return `rgba(${r},${g},${b},${alpha})`;
};

export const rateFilter = (value: string | number) => {
  if (!value && value !== 0) return "";
  const parsedValue = parseFloat(String(value));
  if (parsedValue) {
    const result = `${parsedValue.toFixed(2)}%`;
    return result.replace(".", ",");
  }
  return value;
};

export const currencyFilter = (value?: string | number | null) => {
  if (!value && value !== 0) return "Não definido";

  if (Number(value) || value === 0) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(Number(value));
  }

  return value;
};

export const installmentsFormatter = (value: string, installments: number) => {
  if (value && installments) {
    return `${installments}x ${currencyFilter(value)}`;
  }
  return "Não definido";
};

export const removeSpecialsCharacters = (value: string) => {
  return value?.replace(/\W/g, "") || "";
};

export const moneyToNumber = (value: string) => {
  if (!value) return "Não definido";

  return value
    .replace(/[^0-9+-Ee.]/g, "")
    .replace(/\./g, "")
    .replace(/,/g, ".");
};

export const installmentToNumber = (value: string) => {
  return Number(value.replace(" parcelas", ""));
};

export const stringMoneyToNumber = (value: string) => {
  if (!value.length) return NaN;

  const onlyNumberInString = value
    .replace(/[^0-9+-Ee.]/g, "")
    .replace(/\./g, "")
    .replace(/,/g, ".");

  return Number(onlyNumberInString);
};

export const cellPhoneFormatter = (value: string) => {
  value = value.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
  return value;
};

export const simulationValues = (value: string, type: string) => {
  if (["insurance", "loanValue"].includes(type)) {
    return currencyFilter(value);
  }
  if (type === "loanInstallments") return `${value} meses`;
  if (!value) return "Não definido";

  return value;
};

export const formatDaysAgo = (value: string) => {
  if (!value) return "Não definido";
  return formatDistanceToNow(new Date(value), {
    locale: pt,
  });
};

export const formatDaysAgoStrict = (value: string) => {
  if (!value) return "Não definido";
  return formatDistanceToNowStrict(new Date(value), {
    locale: pt,
  });
};

export const formatCPForCNPJ = (document = "") => {
  if (!document) return document;
  if (document.length === 14) {
    document = document.padStart(14, "0");
    document = document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  } else {
    document = document.padStart(11, "0");
    document = document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return document;
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  if (value.length === 10) return value.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3");
  return value.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
};

export const formatDate = (
  date: string | Date,
  formatPattern = "HH:mm | dd/MM/yyyy",
  options = {}
) => {
  if (!date) return date;
  const parsedDate = typeof date === "string" ? parseISO(date) : new Date(date);
  return format(parsedDate, formatPattern, { locale: pt, ...options });
};

export const toDate = (date: string | Date, format = "DD/MM/YYYY"): string => {
  if (!date || (typeof date === "string" && !date.length)) return "";

  return moment(date).format(format);
};

export const formatStringDateToISO = (value: string) => {
  return value.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, "$3-$2-$1");
};

export const naturalnessConverter = (value: string | null) => {
  if (!value) return { state: "", city: "" };
  const [city, state] = value.split("-");
  return { state: state?.trim() || "", city: city?.trim() || "" };
};

export const maskCPF = (value: string, start = 3, end = 2) => {
  if (!value) return value;
  const firstPart = value.substring(0, start);
  const maskedPart = value.substring(start, value.length - end).replace(/[0-9]/g, "X");
  const lastPart = value.substring(value.length - end);
  return `${firstPart}${maskedPart}${lastPart}`;
};

export const uppercaseOnlyFirstLetter = (value: string): string => {
  const firstLetterUppercased = value[0].toUpperCase();
  const stringWithoutFirstLetter = value.substring(1, value.length) as string;
  return `${firstLetterUppercased}${stringWithoutFirstLetter}`;
};

export const calculateDatePlusNDays = (initialDate: string, daysToAdd: number) => {
  const splitedDate = initialDate.split("/");
  const data = new Date(
    parseInt(splitedDate[2]),
    parseInt(splitedDate[1]) - 1,
    parseInt(splitedDate[0])
  );

  const datePlusXDays = new Date(data.getTime() + daysToAdd * 24 * 60 * 60 * 1000);

  const day = datePlusXDays.getDate();
  const moth = datePlusXDays.getMonth() + 1;
  const year = datePlusXDays.getFullYear();

  const formattedDay = day < 10 ? "0" + day : day;
  const splitedDay = moth < 10 ? "0" + moth : moth;

  return formattedDay + "/" + splitedDay + "/" + year;
};

Vue.filter("rateFilter", rateFilter);
Vue.filter("currencyFilter", currencyFilter);
Vue.filter("installmentsFormatter", installmentsFormatter);
Vue.filter("removeSpecialsCharacters", removeSpecialsCharacters);
Vue.filter("moneyToNumber", moneyToNumber);
Vue.filter("simulationValues", simulationValues);
Vue.filter("formatDate", formatDate);
Vue.filter("formatDaysAgo", formatDaysAgo);
Vue.filter("formatDaysAgoStrict", formatDaysAgoStrict);
Vue.filter("formatCPForCNPJ", formatCPForCNPJ);
Vue.filter("maskCPF", maskCPF);
Vue.filter("uppercaseOnlyFirstLetter", uppercaseOnlyFirstLetter);
Vue.filter("calculateDatePlusNDays", calculateDatePlusNDays);
Vue.filter("toDate", toDate);
